h1,
h2,
h3,
h4,
b,
li,
p {
    color: #f8f8f2de;
}

code {
    color: #ff79c6;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
}

a {
    color: #bd93f9;
}

a:hover {
    color: #50fa7b;
}

.tools-div {
    margin-top: 2vw;
    margin-bottom: 2vw;
    padding-left: 1vw;
    border-left: 2px solid #bd93f9;
}

::selection {
  background: #ff79c6; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ff79c6; /* Gecko Browsers */
}

.writeup-div {
    max-width: 90vw;
}